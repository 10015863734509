<template>
  <v-tooltip
    top
    :color="tooltipColor"
    :disabled="!showTooltip"
    :value="showTooltip"
  >
    <template v-slot:activator="{ on, attrs }">
      <span>{{ label }}</span>
      <div
        style="border: 1px solid"
        :style="{
          borderColor: borderColor || $vuetify.theme.themes.light.border,
          backgroundColor: $vuetify.theme.themes.light.inputBackground,
          maxWidth,
          height: $vuetify.breakpoint.mobile? '32px' : '40px'
        }"
        class="input-container align-center"
        v-bind="attrs"
        v-on="on"
      >
        <div
          v-if="leftIcon"
          class="icon-content"
        >
          <v-icon
            :small="$vuetify.breakpoint.mobile"
            :color="leftIconSelected ? iconSelected : iconColor"
          >
            {{ leftIcon }}
          </v-icon>
        </div>
        <template v-if="chip">
          <v-chip
            small
            close
            @click:close="$emit('removeChip')"
            @click="$emit('chipClicked')"
          >
            {{ chip }}
          </v-chip>
          <v-spacer />
        </template>
        <input
          v-else
          v-model="inputText"
          :placeholder="placeholder"
          :class="leftIcon? '' : 'pl-3'"
          :name="name"
          class="input-text body-2 text-sm-body-1 textBlack--text"
          style="width: 100%"
          :data-cy="dataCy"
          :type="isNumberOnly? 'number' : 'text'"
          :disabled="disabled"
          @focus="handleFocus"
          @blur="handleBlur"
          @input="$emit('set-new-value')"
          @keyup.enter="$emit('set-new-value-enter')"
        >
        <span
          v-if="suffix"
          class="
            d-flex align-center justify-end mr-3 input-text body-2 text-sm-body-1 textBlack--text
          "
        >
          {{ suffix }}
        </span>
        <div
          v-if="rightIcon"
          class="d-flex align-center justify-center mr-1"
        >
          <v-btn
            icon
            text
            :small="$vuetify.breakpoint.mobile"
            :class="rightIconSelected && 'primaryHighlight'"
            @click="$emit('rightIconClicked')"
          >
            <v-icon
              :small="$vuetify.breakpoint.mobile"
              :color="rightIconSelected ? iconSelected : iconColor"
            >
              {{ rightIcon }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </template>
    <span>{{ tooltipMessage }}</span>
  </v-tooltip>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

export default {
  name: 'InputText',
  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    leftIcon: { type: String, default: '' },
    leftIconSelected: { type: Boolean, default: false },
    rightIcon: { type: String, default: '' },
    rightIconSelected: { type: Boolean, default: false },
    maxWidth: { type: String, default: undefined },
    value: { type: String, default: '' },
    tooltipColor: { type: String, default: '' },
    tooltipMessage: { type: String, default: '' },
    chip: { type: String, default: '' },
    suffix: { type: String, default: '' },
    name: { type: String, default: '' },
    disabled: Boolean,
    showTooltip: Boolean,
    isError: Boolean,
    isLowerCase: Boolean,
    isNumberOnly: Boolean,
    noSpaces: Boolean,
    dataCy: { type: String, default: '' },
  },
  data() {
    return {
      isInputFocused: false,
      content: this.value,
    };
  },
  computed: {
    inputText: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.content = value;
      },
    },
    borderColor() {
      if (this.isError) {
        return this.$vuetify.theme.themes.light.warn;
      }

      if (this.isInputFocused) {
        return this.$vuetify.theme.themes.light.primary;
      }

      return this.$vuetify.theme.themes.light.border;
    },
    iconColor() {
      if (this.isError) {
        return this.$vuetify.theme.themes.light.warn;
      }

      if (this.isInputFocused) {
        return this.$vuetify.theme.themes.light.primary;
      }

      return this.$vuetify.theme.themes.light.neutral;
    },
    iconSelected() {
      if (this.leftIconSelected || this.rightIconSelected) {
        return this.$vuetify.theme.themes.light.primary;
      }

      return this.$vuetify.theme.themes.light.neutral;
    },
  },
  watch: {
    content() {
      if (this.isLowerCase) {
        this.toLowerCase();
      }

      if (this.noSpaces) {
        this.removeAllSpaces();
      }
    },
  },
  methods: {
    handleFocus() {
      this.$emit('focused');
      this.isInputFocused = true;
    },
    handleBlur() {
      this.$emit('blurred');
      this.isInputFocused = false;
    },
    toLowerCase() {
      this.content = this.content.toLowerCase();
      this.$emit('input', this.content);
    },
    removeAllSpaces() {
      this.content = this.content.trim().replace(/\s/g, '');
      this.$emit('input', this.content);
    },
  },
};
</script>

<style scoped>
  .input-container{
    display: flex;
    flex: 1;
    height: 44px;
    flex-direction: row;
    border-radius: 4px;
    border: 1px solid black;
  }

  .icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }

  .input-text {
    outline: 0;
    border: 0;
    flex: 1;
  }
</style>
