<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="cancel"
  >
    <v-card
      class="px-6 py-8"
    >
      <v-toolbar
        dark
        :color="options.color"
        dense
        flat
        class="align-center justify-space-between"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ isCreateTagMode? $t('deconve.tag.newTag') : $t('deconve.tag.addTags') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="cancel"
        />
      </v-toolbar>
      <v-card-text
        v-if="isCreateTagMode"
        class="pa-4"
      >
        <div>
          <span class="font-weight-medium caption text-sm-body-2">
            {{ $t('deconve.tag.tagType') }}
          </span>
          <div class="d-flex">
            <v-radio-group
              v-model="tagType"
              row
            >
              <v-radio
                :value="keyAndValue"
                :label="$t('deconve.tag.type.keyAndValue')"
              />
              <v-radio
                :value="keyOnly"
                :label="$t('deconve.tag.type.keyOnly')"
              />
              <template v-if="$vuetify.breakpoint.mobile">
                <!-- <v-radio
                  :value="sharedTagKey"
                  :label="$t('deconve.tag.type.shared')"
                /> -->
              </template>
            </v-radio-group>
          </div>
        </div>
        <div class="mb-4">
          <span class="font-weight-medium caption text-sm-body-2">
            {{ $t('deconve.tag.tagColor') }}
          </span>
          <v-sheet
            v-if="!showColorPicker"
            class="mt-2"
            outlined
            :color="tagColor"
            height="34"
            width="48"
            rounded
            @click="showColorPicker = true"
          />
          <v-color-picker
            v-if="showColorPicker"
            v-model="tagColor"
            class="mt-2"
            mode="hexa"
            width="100%"
            hide-inputs
            hide-canvas
            hide-sliders
            hide-mode-switch
            show-swatches
            @input="showColorPicker = false"
          />
        </div>
        <div v-if="(tagType === keyAndValue) || (tagType === keyOnly)">
          <span class="font-weight-medium caption text-sm-body-2">
            {{ $t('deconve.tag.key') }}
          </span>
          <input-text
            v-model="tagKey"
            left-icon="mdi-key-outline"
            :placeholder="$t('deconve.tag.key')"
          />
        </div>
        <div
          v-if="tagType !== keyOnly"
          class="mt-2"
        >
          <span class="font-weight-medium caption text-sm-body-2">
            {{ $t('deconve.tag.value') }}
          </span>
          <input-text
            v-model="tagValue"
            left-icon="mdi-label-outline"
            :placeholder="$t('deconve.tag.value')"
          />
        </div>
        <div class="d-flex">
          <rectangle-button
            class="mt-6"
            color="primary"
            icon="mdi-plus"
            @clicked="handleNewTag"
          >
            {{ $t('deconve.tag.createTag') }}
          </rectangle-button>
        </div>
        <div
          v-if="newTags.length > 0"
          class="mt-4"
        >
          <span
            class="subtitle-2 text-sm-subtitle-1 font-weight-medium neutral--text"
          >
            {{ $t('deconve.tag.createdTags') }}
          </span>
          <div class="d-flex flex-wrap">
            <tag
              v-for="(tag, index) in newTags"
              :key="index"
              show-delete-label
              class="pa-1"
              :label="tag.key"
              :value="tag.value"
              :color="tag.color"
              @delete="deleteNewTag(index)"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-text
        v-else
        class="pa-4"
      >
        <input-text
          v-model="search"
          left-icon="mdi-magnify"
          :placeholder="$t('deconve.search')"
          @focused="isListActive = true"
        />
        <div
          class="mb-2 mt-6"
          style="max-height: 280px; overflow-y: auto"
        >
          <v-treeview
            v-model="selectedTags"
            :search="search"
            :items="formattedTags"
            item-children="child"
            item-text="value"
            selectable
            selected-color="primary"
            return-object
          >
            <template v-slot:label="{ item }">
              {{ translateTagName(item.value) }}
            </template>
          </v-treeview>
        </div>
      </v-card-text>
      <v-card-text v-if="!isCreateTagMode">
        <div
          v-if="selectedTags.length > 0"
          class="subtitle-2 text-sm-subtitle-1 font-weight-medium neutral--text"
        >
          {{ $t('deconve.tag.addedTags') }}
        </div>
        <div class="d-flex flex-wrap">
          <tag
            v-for="(tag, index) in selectedTags"
            :key="tag.id"
            show-delete-label
            class="pa-1"
            :tag-id="tag.id"
            @delete="deleteSelectedTag(index)"
          />
        </div>
      </v-card-text>
      <v-card-actions
        class="pt-2"
      >
        <rectangle-button
          v-if="canCreate && !isCreateTagMode"
          color="white"
          content-color="neutral"
          icon="mdi-plus"
          @clicked="isCreateTagMode = true"
        >
          {{ $t('deconve.tag.newTag') }}
        </rectangle-button>
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none px-3"
          color="neutral"
          @click="cancel"
        >
          {{ $t('deconve.cancel') }}
        </v-btn>
        <v-btn
          v-if="!(isCreateTagMode && newTags.length === 0) "
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none px-3"
          color="primary"
          @click="confirm"
        >
          {{ $t('deconve.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import RectangleButton from './RectangleButton.vue';
import SquareButton from './SquareButton.vue';
import InputText from './InputText.vue';
import Tag from './Tag.vue';

export default {
  name: 'TagsManagerDialog',
  components: {
    RectangleButton,
    SquareButton,
    InputText,
    Tag,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    availableTags: Array,
    workspaceId: { type: String, default: undefined },
    isOnDeconveHub: { type: Boolean, default: false },
  },
  data: () => ({
    search: '',
    canCreate: false,
    isListActive: true,
    isCreateTagMode: false,
    showColorPicker: true,
    tagType: 'keyAndValue',
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    keyAndValue: 'keyAndValue',
    keyOnly: 'keyOnly',
    sharedTagKey: '__shared_with_workspace__',
    propTags: [],
    selectedTags: [],
    defaultSelectedTags: [],
    formattedTags: [],
    newTags: [],
    tagKey: '',
    tagValue: '',
    tagColor: '#FF0000',
    options: {
      color: 'white',
      width: 480,
    },
  }),
  computed: {
    ...mapGetters({
      tags: 'tags/getTags',
    }),
  },
  watch: {
    tags() {
      this.parseTags();
    },
    workspaceId() {
      if (this.isOnDeconveHub && this.workspaceId) {
        this.fetchTags(this.workspaceId);
      }
    },
  },
  methods: {
    ...mapActions({
      createTag: 'tags/createTag',
      fetchTags: 'tags/fetchTags',
    }),
    open(tags, canCreate = false) {
      this.canCreate = canCreate;
      this.defaultSelectedTags = tags;
      this.parseTags();
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    translateTagName(tag) {
      if (tag === this.sharedTagKey) {
        return this.$t('deconve.tag.type.shared');
      }

      return tag;
    },
    parseTags() {
      let availableTags = [];

      if (this.availableTags) {
        this.tags.forEach(
          (tag) => {
            const isAvailableTag = this.availableTags.findIndex(
              (avTag) => avTag.id === tag.id,
            ) !== -1;

            if (isAvailableTag >= 0) {
              availableTags.push(tag);
            }
          },
        );
      } else {
        availableTags = this.tags;
      }

      this.formattedTags = this.prepareDataForTreeview(availableTags);
      this.defineDefaultSelectedTags();
    },
    sortTagsByName(tags) {
      const tagsSortedByName = tags;

      tagsSortedByName.sort((currentTag, nextTag) => {
        const currentTagInLowerCase = currentTag.value.toLowerCase();
        const nextTagInLowerCase = nextTag.value.toLowerCase();

        return currentTagInLowerCase.localeCompare(nextTagInLowerCase);
      });

      tagsSortedByName.forEach((tag) => {
        tag.child.sort((currentChildTag, nextChildTag) => {
          const currentChildTagInLowerCase = currentChildTag.value.toLowerCase();
          const nextChildTagInLowerCase = nextChildTag.value.toLowerCase();

          return currentChildTagInLowerCase.localeCompare(nextChildTagInLowerCase);
        });
      });

      return tagsSortedByName;
    },
    prepareDataForTreeview(tags) {
      const formattedTags = [];

      // `tag:value` is added as a branch, while tag without value is a single node, even when
      // there are `tag01:value01` and `tag01` tags

      tags.forEach((tag) => {
        const { id: tagId, name, value: tagValue } = tag;

        if (tagValue) {
          const tagList = formattedTags.find(({ id }) => id === name);

          if (tagList) {
            tagList.child.push({ id: tagId, value: tagValue });
          } else {
            formattedTags.push({ id: name, value: name, child: [{ id: tagId, value: tagValue }] });
          }
        } else {
          formattedTags.push({ id: tagId, value: name, child: [] });
        }
      });

      const formattedAndSortedTags = this.sortTagsByName(formattedTags);

      return formattedAndSortedTags;
    },
    defineDefaultSelectedTags() {
      this.selectedTags = this.defaultSelectedTags.map((tag) => ({ id: tag.id }));
    },
    confirm() {
      if (this.isCreateTagMode) {
        this.handleConfirmCreateTag();
      } else {
        this.resolve(this.selectedTags);
        this.reset();
        this.dialog = false;
      }
    },
    reset() {
      this.propTags = [];
      this.newTags = [];
      this.formattedTags = [];
      this.defaultSelectedTags = [];
      this.canCreate = false;
    },
    cancel() {
      if (this.isCreateTagMode) {
        this.isCreateTagMode = false;
      } else {
        this.resolve();
        this.dialog = false;
        this.reset();
      }
    },
    deleteNewTag(index) {
      this.newTags.splice(index, 1);
    },
    deleteSelectedTag(index) {
      if (this.selectedTags[index]) {
        this.selectedTags.splice(index, 1);
      }
    },
    handleNewTag() {
      if (this.tagType === this.sharedTagKey) {
        this.tagKey = this.sharedTagKey;
      }

      this.newTags.push({
        key: this.tagKey,
        value: this.tagType === this.keyOnly ? '' : this.tagValue,
        color: this.tagColor,
      });
    },
    handleConfirmCreateTag() {
      const createTagPromises = [];

      this.newTags.forEach((newTag) => {
        const { key: name, value, color } = newTag;
        const payload = {
          tag: { name, value, color },
        };

        if (this.isOnDeconveHub && this.workspaceId) {
          payload.workspaceId = this.workspaceId;
        }

        createTagPromises.push(this.createTag(payload));
      });

      Promise.all(createTagPromises).then(() => {
        this.newTags = [];
        this.isCreateTagMode = false;
        this.defaultSelectedTags = this.selectedTags.map((tag) => ({ id: tag.id }));
      }).catch(() => {
        this.newTags = [];
        this.isCreateTagMode = false;
        this.defaultSelectedTags = this.selectedTags.map((tag) => ({ id: tag.id }));
      });
    },
  },
};
</script>
